import { OrganizationList } from '../components/Organization';

export function OrganizationsListRoute() {
  return <OrganizationList />;
}

export const Component = OrganizationsListRoute;

export function clientLoader() {
  return null;
}
